// Initialize the Speech library and the instance of Unity
window.SetUnityUndefined = function () {
  window.UnityInstance = undefined;
}

window.InitUnityController = function () {
  const UnityInstance = window.UnityInstance;

  window.visualViewport?.addEventListener('resize', e => SendScreenSizeChange());
  window.visualViewport?.addEventListener('resize', e => SendOrientationChange());

  this.height = window.visualViewport.height;
}

// Variables
window.totalTTS_Time = 0;

function receiveParamsLoadedFromUnity() {
  console.log("receive Params Loaded From Unity");
  // window.receiveParamsLoaded();
}

function receiveSpeechFinishedFromUnity() {
  window.receiveSpeechFinished();
}

function receiveAvatarLoadedFromUnity() {
  window.receiveAvatarLoaded();
}

// Receive the total time of TTS from Unity
function SetTts(timeTts) {
  window.totalTTS_Time += timeTts;
}

function SendScreenSizeChange() {
  if (window.visualViewport.height < this.height * 0.7) {
    if (UnityInstance !== undefined) {
      UnityInstance.send("JSReceiver", "ScreenChangeOnKeyboardOpen", 1);
      window.receiveKeyboardOnOff(true);
    }
  } else {
    if (UnityInstance !== undefined) {
      UnityInstance.send("JSReceiver", "ScreenChangeOnKeyboardOpen", 0);
      window.receiveKeyboardOnOff(false);
    }
  }
}

function SendOrientationChange(){
  if (UnityInstance !== undefined) {
    UnityInstance.send("JSReceiver", "SetOrientation", (window.matchMedia("(orientation: portrait)").matches) ? 1 : 0);
  }
}

function SendLanguageChange(lang) {
  if (UnityInstance !== undefined) {
    UnityInstance.send("JSReceiver", "SetLanguageVoice", lang);
  }
}

function SendUserInteraction(interaction) {
  if (UnityInstance !== undefined) {
    UnityInstance.send("JSReceiver", "ReceiveUserInteraction", interaction);
  }
}
